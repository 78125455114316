<template>
  <div class="gauge__outer">
    <div class="gauge__inner">
      <div class="gauge__fill" :style="{ transform: `rotate(${cssTransformRotateValue})` }"></div>
      <div class="gauge__cover">
        {{ percentage.toFixed(2) }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    percentage: {
      type: Number,
      required: true,
    },
    max: {
      type: Number,
      required: true,
    }
  },
  data() {
    return {
      cssTransformRotateValue: '0turn',
    };
  },
  computed: {
    halfPercentage() {
      const percentageAsFraction = this.percentage / this.max;
      return percentageAsFraction / 2;
    }
  },
  watch: {
    halfPercentage: {
      handler() {
        if(this.percentage < this.max){
          this.cssTransformRotateValue = `${this.halfPercentage}turn`;
        } else {
          this.cssTransformRotateValue = `100turn`;
        }

      },
      immediate: true
    }
  }
};
</script>

<style scoped>
.gauge__outer {
  width: 100%;
  max-width: 220px;
}

.gauge__inner {
  width: 100%;
  height: 0;
  padding-bottom: 50%;
  background: #b4c0be;
  position: relative;
  border-top-left-radius: 100% 200%;
  border-top-right-radius: 100% 200%;
  overflow: hidden;
}

.gauge__fill {
  position: absolute;
  top: 100%;
  left: 0;
  width: inherit;
  height: 100%;
  background: #2196f3;
  transform-origin: center top;
  transform: rotate(0turn);
}

.gauge__cover {
  width: 75%;
  height: 150%;
  background: #171a1d;
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 50%;

  /* Text */
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 25%;
  box-sizing: border-box;
  font-family: 'Lexend', sans-serif;
  font-weight: bold;
  font-size: 32px;
}
</style>
