<template>
  <v-container fluid>
    <v-row class="pl-16">
      <v-col cols="5">
        <v-row class="mt-5">
          <Menu></Menu>
          <div class="text-h4 font-weight-bold">Tempo de exames por setor</div>
        </v-row>
        <v-row>
          <div class="light-blue-rectangle mt-3"></div>
        </v-row>
      </v-col>
      <v-col cols="7" class="justify-space-around">
        <v-row>
          <v-col cols="6" class="mt-10">
              <date-picker style="max-width: 150px" v-model="params.dataInicio" value-type="DD/MM/YYYY" format="DD/MM/YYYY" placeholder="Data de início"
                           lang="pt-br"></date-picker>
              <date-picker style="max-width: 150px" v-model="params.dataFinal" value-type="DD/MM/YYYY" format="DD/MM/YYYY" placeholder="Data final"
                           lang="pt-br"></date-picker>
            </v-col>
            <v-col cols="6">
              <v-list-item class="mt-6 justify-end pr-16 mr-16 pt-2">
                <div class="text-center">
                  <v-dialog v-model="modal"  transition="dialog-top-transition" max-width="800">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn @click="modal = true" v-bind="attrs" v-on="on">
                        <v-icon title="Filtro">mdi mdi-filter</v-icon>
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title>
                        Filtros
                      </v-card-title>
                      <v-divider></v-divider>
                      <v-card-text>
                        <v-row class="justify-space-around pt-2">
                          <v-col cols="5">
                            <v-select v-model="cnpjsSelecionados" :items="cnpjs" item-text="cnpj" label="Laboratórios" multiple chips hint="Selecione os laboratórios que serão exibidos" persistent-hint></v-select>
                          </v-col>
                          <v-col cols="6">
                            <v-autocomplete v-model="params.tipoDeExameFiltro" clearable chips label="Tipo de exame" :items="tiposDeExamePorCNPJSemFiltro.tiposDeExamePorCNPJ.map(obj => obj.tipoExame)" multiple></v-autocomplete>
                          </v-col>
                        </v-row>
                      </v-card-text>
                      <v-divider></v-divider>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" text @click="modal = false; loadData();">
                          Pesquisar
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </div>
              </v-list-item>
            </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="mt-3">
      <v-col cols="3">
        <div class="chart-title pl-8 pr-8 pt-2">
          <h3 class="chart-title">Média de dias - Macroscopia</h3>
          <Gauge :percentage="this.mediaDeDiasMacroscopia[1]" :max="20"></Gauge>
        </div>
        <div class="chart-title pl-8 pr-8 pt-16">
          <h3 class="chart-title">Média de dias - Microscopia</h3>
          <Gauge :percentage="this.mediaDeDiasMicroscopia[1]" :max="20"></Gauge>
        </div>
        <div class="chart-title pl-8 pr-8 pt-16">
          <h3 class="chart-title">Média de dias - Conclusão</h3>
          <Gauge :percentage="this.mediaDeDiasConclusao[1]" :max="20"></Gauge>
        </div>
      </v-col>
      <v-col cols="9">
        <v-row class="justify-space-around">
          <v-col cols="12">
            <div class="tabela pr-10" id="tabela">
              <h3 class="chart-title">Tempo médio de entrega por tipo de exame</h3>
              <div id="linhasTempoMedioDeEntregaPorTipoDeExame"></div>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <div class="pr-8">
              <div id="mediaDiasDeEntregaPorTipoDeExame"></div>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import api from "@/plugins/api";
import {GoogleCharts} from "google-charts";
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/pt-br';
import DatePicker from "vue2-datepicker";
import Swal from "sweetalert2";
import Gauge from "@/components/gauge.vue";
import Menu from "@/components/Menu.vue";

export default {
  name: "tempoDeExamesPorSetor",
  components: {Menu, DatePicker, Gauge},

  data() {
    let currentDate = new Date();
    currentDate.setMonth(currentDate.getMonth() - 6);
    let formattedDate = currentDate.toLocaleDateString();
    return {
      modal: false,
      data_liberacao: [],
      tipoExame: [],
      mediaDias: [],
      cnpjsSelecionados: [],
      cnpjs: [],
      tiposDeExamePorCNPJSemFiltro: [],
      params: {
        cnpj: [],
        dataInicio: formattedDate,
        dataFinal: new Date().toLocaleDateString('pt-br'),
        tipoDeExameFiltro: [],
      },
      mediaDeDiasMacroscopia: [],
      mediaDeDiasMicroscopia: [],
      mediaDeDiasConclusao: [],
      headers: [
        { text: 'Tipo de exame', value: 'tipoExame' },
        { text: 'Liberação', value: 'data_liberacao',},
        { text: 'Média de dias', value: 'mediaDias' },
      ],
      larguraTable: this.larguraTable,
      search: '',
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      tempoMedioDeEntregaPorTipoDeExame: [],
    }
  },

  created() {
    this.listaDeLaboratorios();
    addEventListener("resize", () => {
      let seTiver = document.getElementById('mediaDiasDeEntregaPorTipoDeExame').offsetWidth
      if(seTiver){
        this.larguraTable = document.getElementById('mediaDiasDeEntregaPorTipoDeExame').offsetWidth;
      }
      this.drawChartLinhasTempoMedioDeEntregaPorTipoDeExame();
      this.drawChartMediaDiasPorTipoDeExame();
    });
  },

  mounted() {
    this.larguraTable = document.getElementById('mediaDiasDeEntregaPorTipoDeExame').offsetWidth;
  },

  watch: {
    'params.dataInicio': 'loadData',
    'params.dataFinal': 'loadData',
    cnpjsSelecionados: {
      handler(newValue) {
        // Verificar se o valor atual é diferente do valor armazenado
        const storedCNPJs = JSON.parse(localStorage.getItem('selectedCNPJs'));
        if (JSON.stringify(newValue) !== JSON.stringify(storedCNPJs)) {
          localStorage.setItem('selectedCNPJs', JSON.stringify(newValue));
          this.loadData();
        }
      },
    },
    'params.tipoDeExameFiltro': 'loadData',
  },

  methods: {
    listaDeLaboratorios(){
      api.post('api/autenticacao/listaDeLaboratorios', this.params).then(response => {
        this.cnpjs = response.data;
        this.primeiroCNPj = this.cnpjs[0].cnpj
        this.loadData();
      });
    },

    loadData() {
      const selectedCNPJs = localStorage.getItem('selectedCNPJs');

      if (!selectedCNPJs) {
        this.params.cnpj = [this.primeiroCNPj];
        this.cnpjsSelecionados = this.params.cnpj;
      } else {
        this.params.cnpj = JSON.parse(selectedCNPJs);
        this.cnpjsSelecionados = this.params.cnpj
      }

      api.post('api/tempoDeExamesPorSetor/mediaDeDiasMacroscopia', this.params).then(async response => {
        this.mediaDeDiasMacroscopia = response.data;
        await new Promise((resolve) => {
          GoogleCharts.load('current', {'packages': ['gauge'], callback: resolve});
        });
      }).catch(error => {
        if (error.response && error.response.status === 401) {
          Swal.fire({
            title: 'Acesso não autorizado',
            text: 'Faça login para continuar.',
            icon: 'error',
            showConfirmButton: true,
            confirmButtonText: 'Fazer login',
            allowOutsideClick: false
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.href = '/';
            }
          });
        }
      });

      api.post('api/tempoDeExamesPorSetor/mediaDeDiasMicroscopia', this.params).then(async response => {
        this.mediaDeDiasMicroscopia = response.data;
      });

      api.post('api/tempoDeExamesPorSetor/mediaDeDiasConclusao', this.params).then(async response => {
        this.mediaDeDiasConclusao = response.data;
      });

      api.post('api/tempoDeExamesPorSetor/tiposDeExameSemFiltro', this.params).then(response => {
        this.tiposDeExamePorCNPJSemFiltro = response.data;
      });

      api.post('api/tempoDeExamesPorSetor/tiposDeExamePorCNPJ', this.params).then(response => {
        this.tiposDeExamePorCNPJ = response.data;
        api.post('api/tempoDeExamesPorSetor/quantidadeDeExamesMensalPorTipoDeExame', this.params).then(response => {
          this.mediaDiasDeEntregaPorTipoDeExame = response.data;
          this.drawChartMediaDiasPorTipoDeExame();
          this.drawChartLinhasTempoMedioDeEntregaPorTipoDeExame();
        });
      });

    },

    drawChartMediaDiasPorTipoDeExame() {
      GoogleCharts.load(() => {
        const data = new GoogleCharts.api.visualization.DataTable();
        data.addColumn('string', 'Mês');

// Itera sobre cada patologista na array de objetos
        Object.keys(this.tiposDeExamePorCNPJ).forEach(tipoExame => {
          const nomesTipoExame = this.tiposDeExamePorCNPJ[tipoExame].map(obj => obj.tipoExame);
          nomesTipoExame.forEach(nome => {
            data.addColumn('number', nome);
          });
        });

// Itera sobre a lista de mediaDiasPatologistaMensal
        this.mediaDiasDeEntregaPorTipoDeExame.forEach(linha => {
          let rowData = [linha[0]];

          for (let i = 1; i < linha.length; i++) {
            rowData.push(linha[i]);
          }

          data.addRows([rowData]);
        });

        const options = {
          chart: {
            title: 'Quantidade de exames mensal',
            subtitle: 'Estatísticas por mês',
            hAxis: -1,
            textStyle: {color: 'white', fontSize: '12', bold: true}
          },
          hAxis: {
            titleTextStyle: {color: '#b0bec5'},
            gridlines: {count: 0},
            textStyle: {color: 'white', fontSize: '12', bold: true}
          },
          vAxis: {
            minValue: 0,
            gridlines: {color: '#37474f', count: 4},
            baselineColor: 'transparent',
            textStyle: {color: 'white', fontSize: '12', bold: true}
          },
          legend: {position: 'top', alignment: 'center', textStyle: {color: 'white', fontSize: '12'}},
          lineWidth: 2,
          chartArea: {
            backgroundColor: "transparent",
            width: '85%',
            height: '80%'
          },
          height: (this.larguraTable / 5) + 50, // example height, to make the demo charts equal size
          width: this.larguraTable,
          pieSliceBorderColor: '#263238',
          pieSliceTextStyle: {color: '#607d8b'},
          pieHole: 0.9,
          bar: {groupWidth: "40"},
          colorAxis: {colors: ["#3f51b5", "#2196f3", "#03a9f4", "#00bcd4"]},
          backgroundColor: "#32373d",
          datalessRegionColor: '#37474f',
          displayMode: 'regions',

          isStacked: true
        };

        const chart = new GoogleCharts.api.visualization.ColumnChart(document.getElementById('mediaDiasDeEntregaPorTipoDeExame'));
        chart.draw(data, options);
      });
    },

    drawChartLinhasTempoMedioDeEntregaPorTipoDeExame(){
      GoogleCharts.load(() => {
        const data = new GoogleCharts.api.visualization.DataTable();
        data.addColumn('string', 'Mês da liberação');

        // Itera sobre cada patologista na array de objetos
        Object.keys(this.tiposDeExamePorCNPJ).forEach(tipoExame => {
          const nomesTipoExame = this.tiposDeExamePorCNPJ[tipoExame].map(obj => obj.tipoExame);
          nomesTipoExame.forEach(nome => {
            data.addColumn('number', nome);
          });
        });

        // Itera sobre a lista de mediaDiasPatologistaMensal
        this.mediaDiasDeEntregaPorTipoDeExame.forEach(linha => {
          let rowData = [linha[0]];

          for (let i = 1; i < linha.length; i++) {
            rowData.push(linha[i]);
          }

          data.addRows([rowData]);
        });



        const options = {
          chart: {
            title: 'Média de Dias por Patologista Mensal',
            subtitle: 'Estatísticas por mês',
            hAxis: -1,
            textStyle: { color: 'white', fontSize: '12', bold: true}
          },
          hAxis: {
            titleTextStyle: {color: '#b0bec5'},
            gridlines: {count: 0},
            textStyle: {color: 'white', fontSize: '12', bold: true}
          },
          vAxis: {
            minValue: 0,
            gridlines: {color:'#37474f', count:4},
            baselineColor: 'transparent'
          },
          legend: {position: 'top', alignment: 'center', textStyle: {color:'white', fontSize: '12'} },
          colors: ["#3f51b5","#2196f3","#8f3f8d","#00bcd4","#009688","#4caf50","#8bc34a","#cddc39",],
          lineWidth: 2,
          chartArea: {
            backgroundColor: "transparent",
            width: '85%',
            height: '80%'
          },
          height: (this.larguraTable / 5), // example height, to make the demo charts equal size
          width: this.larguraTable,
          pieSliceBorderColor: '#263238',
          pieSliceTextStyle:  {color:'#607d8b' },
          pieHole: 0.9,
          explorer: {},
          bar: {groupWidth: "40" },
          colorAxis: {colors: ["#3f51b5","#2196f3","#03a9f4","#00bcd4"] },
          backgroundColor: "#32373d",
          datalessRegionColor: '#37474f',
          displayMode: 'regions'

        };

        const chart = new GoogleCharts.api.visualization.LineChart(document.getElementById('linhasTempoMedioDeEntregaPorTipoDeExame'));
        chart.draw(data, options);
      });
    }
  },
}
</script>

<style>
.title {
  color: #fff; /* Cor branca */
  font-weight: bold; /* Negrito */
}

.chart-title {
  color: #fff; /* Cor branca */
  font-weight: bold; /* Negrito */
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.light-blue-rectangle {
  width: 460px;
  height: 10px;
  background-color: #2196f3;
}

</style>